import { useAuthStore } from "../stores/authStore";
import { getApiServiceInstance } from "./ApiService";
import { getSessionsApiServiceInstance } from '@/lib/api/SessionsApiService';

class UserApiService {
    constructor() {
        this.apiService = getApiServiceInstance();
        this.myGroupsListResponse = undefined;
    }

    async getUserToken() {
        // return if token exists else fetch it
        if (this.userToken) {
            return this.userToken;
        } else {
            const response = await fetch('/auth/api-token', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            this.userToken = data.token;
            return this.userToken;
        }

    }

    async getUserTeams() {
        const teamTagsData = await this.apiService.get(`/team-tags/`);
        const teamTags = teamTagsData?.data
        // store data in zustand store
        this.#generateIndexedTeamTagsIds(teamTags);
        this.#generateTeamTagsIdToName(teamTags);
        useAuthStore.setState((state) => ({
            ...state,
            teamTags
        }))
        return teamTagsData;
    }

    #generateTeamTagsIdToName(teamTags) {
        const teamTagsArray = Object.entries(teamTags) ?? []
        for (let teamTag of teamTagsArray) {
            const entity = teamTag[1]?.entity?.name ?? "-"
            const teamTagName = teamTag[1].name
            const id = teamTag[1].id
            useAuthStore.setState((state) => ({
                ...state,
                unreadeableTeamTagsIdToName: {
                    ...state.unreadeableTeamTagsIdToName,
                    [id]: teamTagName
                },
                teamTagsIdToName: {
                    ...state.teamTagsIdToName,
                    [id]: `${entity} | ${teamTagName}`
                }
            }))
        }
    }

    #generateIndexedTeamTagsIds(teamTags) {
        const teamTagsArray = Object.entries(teamTags) ?? []
        for (let teamTag of teamTagsArray) {
            const entity = teamTag[1]?.entity?.name ?? "-"
            const teamTagName = teamTag[1].name
            const newKey = teamTagName.replace(/\s+/g, '_') //convert spaces to underscores
            const id = teamTag[1].id
            useAuthStore.setState((state) => ({
                ...state,
                indexedTeamTagsIds: {
                    ...state.indexedTeamTagsIds,
                    [entity]: {
                        ...state.indexedTeamTagsIds[entity] || {},
                        [newKey]: id,
                    },
                },
            }))

        }
    }

    updateTeamTagId(entityNameParam, teamTagName, id, oldTeamTagName) {
        let entityName;
        if (entityNameParam) {
            entityName = entityNameParam;
        } else {
            entityName = "-"
        }
        const key = teamTagName.replace(/\s+/g, '_')
        useAuthStore.setState((state) => ({
            ...state,
            indexedTeamTagsIds: {
                ...state.indexedTeamTagsIds,
                [entityName]: {
                    ...state.indexedTeamTagsIds[entityName] || {},
                    [key]: id,
                },
            },
        }))
        useAuthStore.setState((state) => ({
            ...state,
            unreadeableTeamTagsIdToName: {
                ...state.unreadeableTeamTagsIdToName,
                [id]: teamTagName
            },
            teamTagsIdToName: {
                ...state.teamTagsIdToName,
                [id]: `${entityName} | ${teamTagName}`
            }
        }))

    }

    async fetchEditTeamTagsData(coachId) {
        //fetch data
        const statsRes = await this.getUserStats(coachId);
        const userTeamsRes = await this.getUserTeams();
        const sessionsApiSerice = getSessionsApiServiceInstance();

        //generate data structures from the fetched data to facilitate data manipulation
        const teamTagIdToSessions = await sessionsApiSerice.generaterateMapTeamTagIdToSessions();
        const newEntityToGroupObj = await this.#generateNewEntityToGroups(userTeamsRes?.data);
        const entitiesArray = Object.entries(statsRes?.data?.entities);

        //update zustand store
        useAuthStore.setState((state) => ({
            ...state,
            stats: statsRes?.data,
            teamTagIdToSessions,
            newEntityToGroupObj,
            entitiesArray
        }))
    }

    async #generateNewEntityToGroups(groups) {
        const userGroups = {};
        groups.forEach((group) => {
            const entityId = group?.entity?.id ?? "-";
            if (entityId) {
                if (!userGroups[entityId]) userGroups[entityId] = [];
                userGroups[entityId].push(group);
            }
        });
        return userGroups;
    }

    async addUserGroup(groupName, entityId) {
        let url = `/team-tags/${entityId}`
        return await this.apiService.post(url, { name: groupName });
    }

    async addUserGroupWithoutEntity(groupName) {
        let url = `/team-tags`
        return await this.apiService.post(url, { name: groupName });
    }

    async updateUserGroups(teamTagId, groupName) {
        let url = `/team-tags/update/${teamTagId}`
        return await this.apiService.post(url, { name: groupName });
    }

    async deleteUserGroups(teamTagId) {
        let url = `/team-tags/${teamTagId}`
        return await this.apiService.delete(url);
    }

    async getUserStats(coachId) {
        return await this.apiService.get(`/coaches/${coachId}`);
    }

    async login(body) {
        return await this.apiService.unauthorizedPost(`/login`, body);
    }

    async signup(body) {
        return await this.apiService.unauthorizedPost(`/signup`, body);
    }

    async forgotPassword(body) {
        return await this.apiService.unauthorizedPost(`/reset-password`, body);
    }

    async setPassword(action, hash, body) {
        return await this.apiService.unauthorizedPost(`/set-password/${action}/${hash}`, body);
    }

    async getCoachLabels() {
        const coachLabels = await this.apiService.get(`/labels/user/`);

        useAuthStore.setState((state) => ({
            ...state,
            coachLabels
        }))
    }

}

let userApiServiceInstance = undefined;

export function getuserApiServiceInstance() {
    if (!userApiServiceInstance) {
        userApiServiceInstance = new UserApiService();
    }

    return userApiServiceInstance;
}